<template>
  <div class="order-list">
    <nav-bar :nav-info="navInfo"></nav-bar>
    <van-image :src="require('@/assets/img/icon/nav_bar_rigth.png')" @click="handleClick()" style="position: absolute;top: 14px;right: 0;z-index: 1000;padding: 0 0.426667rem;"></van-image>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="order-list">
          <div class="order">
            <van-checkbox-group v-model="checkboxList">
              <div class="order_tile">
                <div class="order_container" v-for="item,index in dataList" :key="index">
                <van-checkbox class="order" :name="item.waybillId">
                  <div class="order_tile_container">
                    <div class="order_title_img1">
                      <van-image
                        :src="require('@/assets/img/icon/cg_order2x.png')"
                      ></van-image>
                    </div>
                    <div class="order_no">装载单号：{{item.loadingno}}</div>
                    <div class="order_title_img2">
                      <span class="fayun">待补全</span>
                    </div>
                  </div>

                  <div class="order_container_time">
                    <span>{{item.createTime | formatDate("YYYY/MM/DD HH:mm:ss")}}</span>
                  </div>
                  <div class="order_userinfo">
                    <!-- 右边订单信息 -->
                    <div class="order_userinfo_rigth">
                      <div class="order_userinfo_rigth1">
                        <div class="order_userinfo_rigth_item">
                          <div>
                            <van-image
                              :src="require('@/assets/img/icon/order_user2x.png')"
                            ></van-image>
                          </div>
                          <div>收货人：{{item.receiverName}}</div>
                        </div>
                        <div
                          class="order_userinfo_rigth_item"
                        >
                          <div>
                            <van-image
                              :src="require('@/assets/img/icon/driver_tel2x.png')"
                            ></van-image>
                          </div>
                          <div class="main_color">{{item.receiverPhone}}</div>
                        </div>
                      </div>
                      <!-- 收货地址 -->
                      <div class="order_userinfo_rigth_adress">
                        <div class="adress_icon">
                          <van-image
                            :src="require('@/assets/img/icon/order_adress2x.png')"
                          ></van-image>
                        </div>
                        <div class="adress_text">
                          {{item.address}}
                        </div>
                      </div>
                    </div>
                  </div>
                </van-checkbox>
                </div>
              </div>
            </van-checkbox-group>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- 补全信息弹层 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '45%' }">
      <el-form
        :model="form"
        ref="form"
        :rules="rules"
        label-width="120px"
        :inline="false"
        size="small"
        label-position="left"
        style="padding: 20px"
      >
        <el-form-item label="车牌号" prop="carNo">
          <el-select
            v-model="searchQuery"
            @change="searchChange"
            @blur="searchBlur"
            filterable
            remote
            clearable
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="searchLoading"
            style="width: 100%"
            value-key="id"
          >
            <el-option
              v-for="item in searchOptions"
              :key="item.id"
              :label="item.carNo"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="司机姓名" prop="driverName">
          <el-input
            v-model="form.driverName"
            placeholder="请填写司机姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="司机手机号" prop="driverPhone">
          <el-input
            :maxlength="11"
            v-model="form.driverPhone"
            placeholder="请填写司机手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="货物重量（t）" prop="cargoWeigth">
          <el-input
            type="number"
            v-model="form.cargoWeigth"
            placeholder="请填写货物重量"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="货物数量" prop="cargoNum">
          <el-input
            type="number"
            v-model="form.cargoNum"
            placeholder="请填写货物数量"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
            style="background-color: #f60; border-color: #f60"
            >提交</el-button
          >
          <el-button @click="show = false">取消</el-button>
        </el-form-item>
      </el-form>
    </van-popup>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
import NavBar from "../../components/NavBar/NavBar.vue";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      //navbar信息
      navInfo: {
        title: "订单列表",
        left: true, //左边图标
        path: "/home", //左边返回的页面地址
      },
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      show: false,
      query: {
        page: 0,
        size: 10,
      },
      form: {
        driverId: "",
        carId: "",
        carNo: "",
        driverName: "",
        driverPhone: "",
        cargoWeigth: "",
        cargoNum: "",
      },
      searchQuery: '',
      checkboxList: [],
      waybillId: [],
      showPopup: false,
      rules: {
        carNo: [{ required: true, message: "请选择车牌号", trigger: "change" }],
        driverName: [
          { required: true, message: "请填写司机姓名", trigger: "blur" },
        ],
        driverPhone: [
          { required: true, message: "请填写司机手机号", trigger: "blur" },
        ],
      },
      searchLoading: false,
      searchOptions: [],
    };
  },
  created() {
    this.$nextTick(() => {
      this.onLoad(true)
    })
  },
  methods: {
    async onLoad(bool) {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
        this.query.page = 1
      } else {
        this.query.page++;
      }
      let params = {
        start: !bool ? this.query.page : 1,
        length: this.query.size,
      };
      let res = await this.$Api.draftListApi(params);
      if (res.status != 200) return false;
      
      if(bool) {
        this.dataList = res.data.data
      } else {
        this.dataList = [...this.dataList, ...res.data.data]
      }

      if(this.query.page >= res.data.totalPage) {
        this.finished = true;
      }
      
      this.loading = false
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad(true);
    },
    // 司机信息模糊查询
    async remoteMethod(query) {
      this.searchOptions = [];
      if (query !== "") {
        let res = await this.$Api.associateApi(query);
        if (res.status == 200 && res.data.success) {
          this.searchOptions = res.data.data.filter((item) => {
            return item.carNo.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
          if(this.searchOptions.length == 0) {
            this.searchOptions.push({carNo: query})
          }
        }
      } else {
        this.searchOptions = [];
      }
    },
    // 模糊查询选中赋值处理事件
    searchChange(item) {
      this.form.driverId = item.id ? item.id : '-1'
      this.form.carId = item.carId ? item.carId : '-1'
      this.form.carNo = item.carNo
      this.form.driverName = item.realName ? item.realName : ''
      this.form.driverPhone = item.phone ? item.phone : ''

      this.searchOptions = [];
    },
    // 模糊查询失去焦点处理事件
    searchBlur() {
      this.searchOptions = [];
    },
    // 补全司机信息弹窗打开
    handleClick() {
      if(this.checkboxList.length == 0) {
        Toast('请勾选后进行补全');
        return false;
      }
      this.show = true;
      this.form.waybillIds = [];
      this.searchQuery = "";
      this.form.driverId = "";
      this.form.carId = "";
      this.form.carNo = "";
      this.form.driverName = "";
      this.form.driverPhone = "";
      this.form.cargoWeigth = "";
      this.form.cargoNum = "";

      this.waybillId = this.checkboxList
    },
    // 补全司机信息提交
    async onSubmit() {
      this.form.waybillIds = this.waybillId
      let res = await this.$Api.completionWaybillApi(this.form)
      
      if(res.status == 200 && res.data.success) {
        Toast(res.data.data[0]);
        setTimeout(function () {
          location.reload();
        }, 2000);
      } else {
        Toast(res.data.data[0])
      }
    },
  },
};
</script>
<style lang="scss">
  .el-select-dropdown {
    z-index: 100000!important;
  }
</style>
<style lang="scss" scoped>
html,
body {
  height: 100%;
}
.data_list {
  margin-bottom: 1.33rem;
}
.order-list {
  background-color: #f2f2f2;
  // height: 100vh;
  overflow: hidden;
  /deep/.van-tabs__line {
    background-color: #fd5c0c !important;
    width: 20vw;
  }
  .order {
    font-size: 0.37rem;
    .order_container {
      margin: 10px;
      padding: 0 10px;
      background-color: #ffffff;
      border-radius: 3px;
    }
    .order_container_jh {
      background-color: #ffffff;
      border-radius: 0.3rem;
    }
    .order_container_time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        padding: 5px 3px;
        color: #999999;
      }
      span:nth-child(2) {
        padding-right: 1.41rem;
      }
    }
    .order_userinfo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
      .order_userinfo_check {
        width: 1.04rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .checked {
          width: 0.5rem;
          height: 0.5rem;
          /deep/.van-checkbox__icon .van-icon {
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }
      .order_userinfo_rigth {
        flex: 1;
        height: 100%;
        padding-right: 0.3rem;
        .order_userinfo_rigth1 {
          // padding-top: 0.21rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // height: 0.53rem;
          span {
            color: #999999;
          }
          .order_userinfo_rigth_item {
            flex: 1;
            display: flex;
            justify-content: left;
            align-items: center;
            div {
              height: 100%;
              line-height: 0.53rem;
              text-align: left;
            }
            /deep/.van-image {
              width: 0.64rem;
              height: 0.64rem;
            }
          }
          .order_userinfo_rigth_item1 {
            width: 4.1rem;
          }
        }
      }
    }

    .order_userinfo_rigth_adress {
      position: relative;
      min-height: 30px;
      .adress_icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.64rem;
        height: 0.64rem;
      }
      .adress_text {
        padding: 3px 0;
        padding-left: 22px;
      }
    }
  }

  .order_tile_container {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid #f2f2f2;
    .order_title_img1 {
      width: 0.64rem;
      height: 0.64rem;
    }
    .order_title_img2 {
      width: 1.4rem;
      height: 0.48rem;
      padding-left: 5px;
      padding-bottom: 0.1rem;
      span {
        display: block;
        font-size: 0.37rem;
        text-align: center;
        width: 100%;
        height: 100%;
        line-height: 0.52rem;
        border-radius: 0.1rem;
        border: 1px solid #6c63ff;
        color: #6c63ff;
        padding: 0 0.1rem;
      }
      .fayun {
        border: 1px solid #f60;
        color: #f60;
      }
    }
    .order_no {
      color: #999999;
      width: 5.5rem;
      height: 0.53rem;
      font-size: 0.37rem;
      line-height: 0.53rem;
    }
  }
}
</style>
